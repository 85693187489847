var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"crop-image-dialog"},[_c('v-dialog',{staticClass:"d-flex align-center",attrs:{"max-width":"500","persistent":""},model:{value:(_vm.showCropper),callback:function ($$v) {_vm.showCropper=$$v},expression:"showCropper"}},[_c('v-card',{staticClass:"pt-6 pb-3"},[_c('v-card-text',{staticClass:"pb-3"},[_c('vue-cropper',{ref:"cropper",attrs:{"container-style":{
                    width: 'fit-content',
                    'max-width': '450px',
                    height: 'fit-content',
                    'max-height': '450px',
                    'min-height': '200px'
                  },"aspect-ratio":1 / 1,"guides":true,"background":false,"drag-mode":"move","src":_vm.uploadedImage,"alt":"Image not available"}})],1),_c('v-card-actions',{staticClass:"py-0 mx-10"},[_c('v-btn',{staticClass:"error",on:{"click":_vm.onCancelClicked}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"primary",on:{"click":_vm.onCropClicked}},[_vm._v(" Crop ")]),_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.cropClicked},on:{"click":_vm.onSaveClicked}},[_vm._v(" Save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }