<template>
    <div class="crop-image-dialog">
        <v-dialog v-model="showCropper" class="d-flex align-center" max-width="500" persistent>
            <v-card class="pt-6 pb-3">
                <v-card-text class="pb-3">
                    <vue-cropper
                      ref="cropper"
                      :container-style="{
                        width: 'fit-content',
                        'max-width': '450px',
                        height: 'fit-content',
                        'max-height': '450px',
                        'min-height': '200px'
                      }"
                      :aspect-ratio="1 / 1"
                      :guides="true"
                      :background="false"
                      drag-mode="move"
                      :src="uploadedImage"
                      alt="Image not available"
                      >
                    </vue-cropper>
                </v-card-text>
                <v-card-actions class="py-0 mx-10">
                    <v-btn
                      class="error"
                      @click="onCancelClicked"
                    >Cancel</v-btn>
                    <v-btn
                      class="primary"
                      @click="onCropClicked"
                    > Crop </v-btn>
                    <v-btn
                      class="primary"
                      :disabled="!cropClicked"
                      @click="onSaveClicked"
                    > Save </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    name: "ImageCropperDialog",
    components: {
        VueCropper,
    },
    props: {
        uploadedImage: {
            type: String,
            default: null,
        },
        onSaveImage: {
            type: Function,
            default: null,
        }
    },
    data() {
        return {
            showCropper: false,
            imageFileType: null,
            cropClicked: false,
        }
    },
    methods: {
        async initCropper(imageFileType) {
            this.showCropper = true;
            this.imageFileType = imageFileType;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.$refs.cropper.replace(this.uploadedImage);
        },
        async onCropClicked() {
            this.$emit('onCrop', this.$refs.cropper.getCroppedCanvas().toDataURL(this.imageFileType));
            this.cropClicked = true;
        },
        onCancelClicked() {
            this.reset();
            this.$emit('cancel-avatar-upload');
        },
        onSaveClicked() {
            this.reset();
            this.onSaveImage();
        },
        reset() {
            this.showCropper = false;
            this.cropClicked = false;
        }
    }
}


</script>